<template src="./status.htm">
 
  

</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Footer from '@/layouts/footer/Footer.vue';

import SubHeader from '@/layouts/sub_header/Subheader.vue';

import env from '../../../../src/services/env';

export default {
  name: "Covid19-Status",
  header_title: env.title,
  components: {
      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    name: '',
    
  view_table: true,
  view_info: false,
  tabs: null,
  is_admin: '',
  date_now: moment().format('LL'),
  new_locations: [
        
        {
          Prov_City: 'Cavity',
          total_cases:'114,802'
        },
        {
          Prov_City: 'Laguna',
          total_cases:'12,417'
        },
        {
          Prov_City: 'Bulacan',
          total_cases:'10,982'
        },
        {
          Prov_City: 'Quezon City',
          total_cases:'9,032'
        },
         
         
         {
          Prov_City: 'Rizal',
          total_cases:'7,490'
        },
         

  ],

  users: [
        {
          status: 'Well',
          total_cases:'17'
        },
         {
          status: 'Positive',
          total_cases:'15'
        },
        
      ],
  userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" }
    ],
   Global_Data: [],
   nationwide: [],
   nationwide_data:'',
   nationwide_data_new_cases:'',
   nationwide_data_total_cases:'',
   nationwide_data_deceased:'',
   Global_total_cases: [],
   Global_cases: [],
   Global_cases_new_cases: [],
   Global_cases_total_cases: [],
   Global_cases_deceased: [],
   Cases_Last_24hrs: [],
   overlay: true,
   overlay2: true,
   progress_Percentage:'0',
   
  }),
   computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
    methods:{
      getInternal_status (){
        setTimeout(() => {
                    this.overlay2 = false
                }, 500)
      },
      myGlobal_total_cases: function(){
              try{
                const result1 = _.chain(this.Global_Data).groupBy('location').map(loc => (
                      _.maxBy(loc, function(o) { return o.total_cases; })
                    )).value();
                var result = _.orderBy(_.filter(result1, _.conforms({'continent': _.negate(_.isUndefined)})),['total_cases','date'],['desc','asc']);
                this.nationwide_data = _.filter(result, function(o) { return o.location === 'Philippines'; });
                return result
              }catch(error){
                console.log(error);
              }
      },
      myCases_Last_24hrs: function(){
              try{
                var myDate = moment(this.date_now).subtract(2, 'days').format('YYYY-MM-DD');

                 const Update_24hrs_data = _.filter(this.Global_Data, function(o) { return o.date === myDate; });
                                 
                var result = _.chain(Update_24hrs_data).groupBy('location').map(loc => (
                              _.maxBy(loc, function(o) { return o.total_cases; })
                            )).value();
                result =  _.orderBy(_.filter(result, _.conforms({'continent': _.negate(_.isUndefined)})),['total_cases'],['desc']);
                return result
              }catch(error){
                console.log(error);
              }
            },
      myGlobal_cases: function() {
          try{
            var myDATA = _.chain(this.Global_Data).groupBy('location').map(loc => (
                      _.maxBy(loc, function(o) { return o.total_cases; })
                    )).value();
            myDATA = _.filter(myDATA, _.conforms({'location': _.negate(_.isUndefined)}));

            myDATA = _.orderBy(myDATA,['total_cases','date'],['desc','asc']);
            //var result = [_.maxBy(myDATA, function(o) { return o.total_cases; })];
            //this.Data_Date = moment(result.date).format("MMM DD,YYYY");
            
            //return result
            }catch(error){
              console.log(error);
            }
      },
      cases_load: function() {
          try{
              var vm =this;
              vm.nationwide_data_new_cases = vm.nationwide_data[0].new_cases;
              vm.nationwide_data_total_cases = vm.nationwide_data[0].total_cases;
              vm.nationwide_data_deceased = vm.nationwide_data[0].deceased;
              vm.Global_cases_new_cases = vm.Global_cases[0].new_cases;
              vm.Global_cases_total_cases = vm.Global_cases[0].total_cases;
              vm.Global_cases_deceased = vm.Global_cases[0].deceased;
              
            }catch(error){
              console.log(error);
            }
      },
    },
    created(){
       this.overlay = true;
    },

  mounted() {
    this.name = this.user.name;
    // this.is_admin = this.user.is_admin;
    this.is_admin = (this.user.is_admin==1 ? true : false);
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }
    this.getInternal_status();
    try{
      this.axios.get('https://covid19-api-philippines.herokuapp.com/api/summary').then((response) => {
        this.nationwide = response.data;

      }).catch(error => console.log(error));
      this.axios.get('https://covid19-api-philippines.herokuapp.com/api/top-regions').then((response) => {
        this.most_location = response.data;
      }).catch(error => console.log(error));
      this.axios.get('https://covid.ourworldindata.org/data/owid-covid-data.json?fbclid=IwAR1lUNNSuSkMH9p8VhSJT_U6cuw9V_ggQm1-wAiguVn92Eii8cIrGok36Vs',
        {
          onDownloadProgress: (progressEvent) => {

            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
              this.progress_Percentage = percentCompleted;
            }
        })
        .then((response) => {
            const world_data = _.flatMap(response.data, function (world_data) { 
              return _.map(world_data.data, function (myData) { 
                  return { continent: world_data.continent, location: world_data.location, total_cases: myData.total_cases, new_cases: myData.new_cases,
                    deceased: myData.total_deaths, date: myData.date
                  };
            });
        });
        this.Global_Data = world_data;

        this.Global_total_cases = this.myGlobal_total_cases();
        this.Global_cases = this.myGlobal_cases();

        this.Cases_Last_24hrs = this.myCases_Last_24hrs();

        this.cases_load();
        this.overlay = false;
      }).catch(error => console.log(error));
      
      }catch(error){
         this.overlay = false;
        console.log(error);
      }
    },
  }
 </script>